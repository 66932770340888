// utils.js
export function formatCurrency(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function convertMoneyToNumber(value) {
  if (value === 0) return value;
  return parseFloat(value.replace(/[^\d,]/g, '').replace(',', '.'));
}