<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Sobre o "Calculadoras Online"</h1>
        <p>
          As calculadoras do "Calculadoras Online" de Imposto de Renda Retido na Fonte
          (IRRF) e Instituto Nacional do Seguro Social (INSS) são ferramentas desenvolvidas
          para auxiliar os contribuintes brasileiros no cálculo do IRRF e das contribuições
          ao INSS, com base no salário bruto mensal e no número de dependentes.
        </p>
        <p>
          Utilizando tecnologias como vue.js, vuex e vuetify, a aplicação apresenta
          uma interface minimalista, sem necessidade de backend, com temas escuro e claro
          e com um menu de navegação superior que facilita sua utilização. Oferece ainda
          a opção de compartilhar o site em redes sociais e de baixar os resultados dos
          cáculos feitos em formato PDF.
        </p>
        <p>
          Além disso as calculadores incluem:<br />
          - seletor de período de referência<br />
          - fórmulas e tabelas de referência para consulta<br />
          - instruções para realizar os cálculos manualmente<br />
          - instruções e fórmulas para realizar os cálculos em Excel<br />
          - informações detalhadas sobre seu funcionamento e objetivos<br />
        </p>
        <p>
          O propósito destas ferramentas é simplificar o processo de cálculo do IRRF
          e INSS e auxiliar os contribuintes a compreender melhor suas obrigações fiscais
          e previdenciárias. Ressaltamos que estas ferramentas não substituem a orientação
          de um contador ou especialista em impostos.
        </p>
        <p>
          O uso das Calculadoras Online é gratuito e aberto a todos os usuários. Contudo,
          não nos responsabilizamos por eventuais danos causados pelo uso destas aplicações.
          Recomendamos sempre a consulta com um contador ou profissional de impostos
          qualificado para garantir a precisão e a conformidade com as leis fiscais e
          previdenciárias vigentes.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { META } from './metaData.js';

export default {
  name: "AboutView",
  metaInfo: META
};
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
}
</style>