<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Calculadoras Online</h1>
        <p>
          Bem-vindo ao Calculadoras Online! Aqui você pode calcular facilmente o Imposto de Renda Retido na Fonte (IRRF) e a contribuição ao Instituto Nacional do Seguro Social (INSS) no Brasil. Explore nossas ferramentas e recursos para obter uma compreensão clara de como esses impostos são calculados.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mx-auto mb-4" max-width="400">
          <v-img height="200" src="https://via.placeholder.com/400x200.png?text=IRRF" alt="Imagem IRRF"></v-img>
          <v-card-title>Calculadora de IRRF</v-card-title>
          <v-card-text>
            Calcule o Imposto de Renda Retido na Fonte (IRRF) com base no seu salário e deduções.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="$router.push({ name: 'IRRF' })">Acessar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mx-auto mb-4" max-width="400">
          <v-img height="200" src="https://via.placeholder.com/400x200.png?text=INSS" alt="Imagem INSS"></v-img>
          <v-card-title>Calculadora de INSS</v-card-title>
          <v-card-text>
            Descubra o valor da sua contribuição ao INSS (Instituto Nacional do Seguro Social) com base no seu salário.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="$router.push({ name: 'INSS' })">Acessar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Recursos Adicionais</h2>
      </v-col>
      <v-col cols="12" md="4">
        <v-list-item link @click="$router.push({ name: 'About' })">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sobre a Ferramenta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file-download</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Fórmulas em Excel</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4">
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Como Calcular Manualmente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <AdZonePlaceholder></AdZonePlaceholder>
  </v-container>
</template>

<script>
import AdZonePlaceholder from '@/components/AdZonePlaceholder.vue';

import { META } from './metaData.js';

export default {
  name: "HomeView",
  metaInfo: META,
  components: {
    AdZonePlaceholder
  }
};
</script>
<style scoped>
h1,
h2 {
  margin-bottom: 1rem;
}
</style>