import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faTwitter, faLinkedinIn, faTiktok, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
import store from './store';

library.add(faFacebookF, faTwitter, faLinkedinIn, faTiktok, faWhatsapp, faInstagram);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.use(VueMeta);
Vue.mixin({
  metaInfo: {
    titleTemplate: 'Calculadoras Online %s',
    meta: [
      {
        name: 'description',
        content: 'Calculadoras Online oferece calculadoras úteis e fáceis de usar para IRRF, INSS e muito mais.'
      },
      {
        name: 'keywords',
        content: 'calculadoras, IRRF, INSS, online, excel, 2023, imposto, renda, seguridade social'
      }
    ]
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
