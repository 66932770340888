<template>
  <v-row>
    <v-col cols="12" md="8">
      <h1>Calculadora de Instituto Nacional do Seguro Social (INSS)</h1>
      <p>
        Esta é uma ferramenta simples e fácil de usar para calcular o
        INSS. Selecione a tabela de INSS pelo período do cálculo, informe
        o salário mensal bruto para obter o valor do INSS.
      </p>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="selectedYearRange"
            :items="yearRanges"
            item-text="label"
            label="Selecione a tabela INSS a utilizar"
            @change="debounceCalculateINSS"
            return-object
          >
            <template v-slot:append>
              <TooltipHelp tooltipText="Escolha a tabela INSS com base no período do cálculo." />
            </template>
          </v-select>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="monthlyGrossIncome"
            v-money="money"
            label="Salário Mensal Bruto"
            type="text"
            :error-messages="validateGrossIncome"
            @input="debounceCalculateINSS"
          >
            <template v-slot:append>
              <TooltipHelp tooltipText="Informe o valor do salário bruto recebido mensalmente." />
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="inss !== null" class="displayOnPDF">
        <v-col cols="12">
          <h2>
            Dado um salario mensal bruto de {{ monthlyGrossIncome }}<br />
            O INSS mensal será de: {{ formatCurrency(inss) }}
          </h2>
        </v-col>
      </v-row>

      <v-row v-if="errorMessage" class="displayOnPDF">
        <v-col cols="12">
          <h2 class="error-message">{{ errorMessage }}</h2>
        </v-col>
      </v-row>

       <FormulasAndTable
        :dependentValue="selectedYearRange.dependentValue"
        :label="selectedYearRange.label"
        :ranges="selectedYearRange.ranges"
      ></FormulasAndTable>

      <ExcelInstructions :selected-year-range="selectedYearRange"></ExcelInstructions>

      <ManualInstructions :selected-year-range="selectedYearRange"></ManualInstructions>

      <FaqList :faq-items="faqItems" />

      <AboutDescription></AboutDescription>
    </v-col>

    <AdZonePlaceholder></AdZonePlaceholder>
  </v-row>
</template>

<script>
import { VMoney } from "v-money";
import { formatCurrency, debounce, convertMoneyToNumber } from "@/utils";
import { validateGrossIncome } from '@/validationMethods.js';

import { YEAR_RANGES } from './yearRanges.js';
import { FAQ_ITEMS } from './faqItems.js';
import { META } from './metaData.js';

import * as components from './components.js';

export default {
  name: "INSSView",
  metaInfo: META,
  directives: {
    money: VMoney,
  },
  components: {
    ...components
  },
  data() { 
    return {
      faqItems: FAQ_ITEMS,
      monthlyGrossIncome: 0,
      inss: null,
      errorMessage: null,
      yearRanges: YEAR_RANGES,
      selectedYearRange: YEAR_RANGES[0],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
    };
  },
  computed: {
    validateGrossIncome() {
      return validateGrossIncome(convertMoneyToNumber(this.monthlyGrossIncome));
    },
  },
  methods: {
    formatCurrency,
    calculateINSS() {
      this.$store.commit('setShowPdfButton', false);
      this.inss = 0;
      this.errorMessage = null;

      if (convertMoneyToNumber(this.monthlyGrossIncome) <= 0) {
        return;
      }

      const baseValue = convertMoneyToNumber(this.monthlyGrossIncome);
      let remainingValue = baseValue;

      for (let i = 0; i < this.selectedYearRange.ranges.length; i++) {
        const bracket = this.selectedYearRange.ranges[i];
        const previousUpperLimit = i > 0 ? this.selectedYearRange.ranges[i - 1].upperLimit : 0;

        if (remainingValue > 0) {
          const rangeValue = bracket.upperLimit - previousUpperLimit;
          const taxableValue = Math.min(rangeValue, remainingValue);
          this.inss += taxableValue * bracket.taxRate;
          remainingValue -= rangeValue;
        } else {
          break;
        }
      }

      this.$store.commit('setShowPdfButton', this.inss !== null);
    },
    debounceCalculateINSS: debounce(function () {
      this.calculateINSS();
    }, 500),
  },
};
</script>
