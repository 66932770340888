<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small>mdi-help-circle</v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TooltipHelp",
  props: {
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>
  