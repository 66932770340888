<template>
  <v-row>
    <v-col cols="12">
      <h3>Como calcular manualmente o IRRF para esse período:</h3>
      <ol>
      <li>Subtraia do salário mensal o valor referente à quantidade de dependentes (R$ {{ formatCurrency(selectedYearRange.dependentValue) }} por dependente).</li>
      <li>Com o resultado, encontre a faixa correspondente na tabela IRRF e aplique a alíquota sobre o valor obtido.</li>
      <li>Subtraia do valor obtido no passo anterior a parcela a deduzir da tabela IRRF.</li>
      <li>O resultado do passo 3 é o valor do IRRF a ser retido.</li>
      </ol>
    </v-col>
  </v-row>
</template>
  
<script>
import { formatCurrency } from "@/utils";

export default {
  props: {
    selectedYearRange: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatCurrency
  }
};
</script>