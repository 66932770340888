<template>
  <div>
  <v-row>
    <v-col cols="12">
      <h3>Fórmulas Utilizadas:</h3>
      <ul>
        <li>Desconto por dependente: {{ formatCurrency(dependentValue) }}</li>
        <li>
          Alíquotas e parcelas a deduzir do IRPF (tabela progressiva de
          {{ label }})
        </li>
      </ul>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <h4>Tabela IRRF para o intervalo {{ label }}:</h4>
      <v-data-table
        :headers="[
          { text: 'Base de Cálculo (R$)', value: 'baseValue' },
          { text: 'Alíquota', value: 'taxRate' },
          { text: 'Parcela a Deduzir (R$)', value: 'deductableValue' }
        ]"
        :items="ranges"
        class="elevation-1"
        hide-default-footer
        :custom-filter="() => true"
      >
        <template slot="item.baseValue" slot-scope="{ item, index }">
          {{ getBaseValue(item, index, ranges) }}
        </template>
        <template slot="item.taxRate" slot-scope="{ item }">
          {{ item.taxRate.toLocaleString('pt-BR', { style: 'percent', minimumFractionDigits: 2 }) }}
        </template>
        <template slot="item.deductableValue" slot-scope="{ item }">
          {{ formatCurrency(item.deductableValue) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils";

export default {
  props: {
    dependentValue: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    ranges: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getBaseValue(row, index, ranges) {
      if (index === 0) {
        return 'de R$ 0,00 até ' + this.formatCurrency(row.upperLimit);
      } else if (index === ranges.length - 1) {
        return 'a partir de ' + this.formatCurrency(ranges[index - 1].upperLimit + 0.01);
      } else {
        return 'de ' + this.formatCurrency(ranges[index - 1].upperLimit + 0.01) + ' até ' + this.formatCurrency(row.upperLimit);
      }
    },
    formatCurrency,
  },
};
</script>
  