<template>
  <div class="share-buttons-container">
    <a :href="facebookShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'facebook-f']" />
    </a>
    <a :href="twitterShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'twitter']" />
    </a>
    <a :href="linkedinShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'linkedin-in']" />
    </a>
    <a :href="tiktokShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'tiktok']" />
    </a>
    <a :href="whatsappShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'whatsapp']" />
    </a>
    <a :href="instagramShareUrl" target="_blank" class="share-button">
      <font-awesome-icon :icon="['fab', 'instagram']" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    shareMessage: {
      type: String,
      default: "Confira essa super ferramenta!"
    }
  },
  computed: {
    encodedShareText() {
      return encodeURIComponent(this.shareMessage);
    },
    facebookShareUrl() {
      const baseUrl = "https://www.facebook.com/sharer/sharer.php";
      const currentUrl = window.location.href;
      return `${baseUrl}?u=${encodeURIComponent(currentUrl)}&quote=${this.encodedShareText}`;
    },
    twitterShareUrl() {
      const baseUrl = "https://twitter.com/intent/tweet";
      const currentUrl = window.location.href;
      return `${baseUrl}?url=${encodeURIComponent(currentUrl)}&text=${this.encodedShareText}`;
    },
    linkedinShareUrl() {
      const baseUrl = "https://www.linkedin.com/shareArticle";
      const currentUrl = window.location.href;
      return `${baseUrl}?mini=true&url=${encodeURIComponent(currentUrl)}&title=${this.encodedShareText}`;
    },
    tiktokShareUrl() {
      const baseUrl = "https://www.tiktok.com/share?url=";
      const currentUrl = window.location.href;
      return baseUrl + encodeURIComponent(currentUrl);
    },
    whatsappShareUrl() {
      const baseUrl = "https://wa.me/?text=";
      const currentUrl = window.location.href;
      return baseUrl + this.encodedShareText + "%0A" + encodeURIComponent(currentUrl);
    },
    instagramShareUrl() {
      const baseUrl = "https://www.instagram.com/";
      return baseUrl;
    },
  }
};
</script>

<style scoped>
.share-button {
  margin-right: 10px;
  font-size: 24px;
  color: #333;
  text-decoration: none;
}

.share-button:hover {
  color: #007bff;
}

.share-buttons-container {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .share-button {
    margin-right: 5px;
    font-size: 18px;
  }
}
</style>
  