<template>
  <v-row>
    <v-col cols="12">
      <h3>Como calcular a contribuição ao INSS no Excel para o período {{ selectedYearRange.label }}:</h3>
      <p>
        Para calcular a contribuição ao INSS no Excel, siga as etapas abaixo. As fórmulas já incluem os valores da tabela de contribuição selecionada.
      </p>
      <h4>Versão compacta:</h4>
      <ol>
        <li>Crie uma célula para o salário mensal bruto (exemplo: A1).</li>
        <li>
          Calcule o valor da contribuição ao INSS em uma única fórmula, utilizando apenas a célula A1:
          <code>
          =A1 * {{ generateFormulaCompact(selectedYearRange.taxBrackets, 'taxRate', 'A1') }}
          </code>
        </li>
      </ol>
      <h4>Versão extendida:</h4>
      <ol>
        <li>Crie uma célula para o salário mensal bruto (exemplo: A1).</li>
        <li>
          Crie uma célula (por exemplo, A2) e insira a seguinte fórmula para encontrar a alíquota correspondente ao salário bruto, utilizando os valores da tabela de contribuição selecionada:
          <code>
          {{ generateFormula(selectedYearRange.taxBrackets, 'taxRate') }}
          </code>
        </li>
        <li>
          Calcule o valor da contribuição ao INSS em uma célula (por exemplo, A3) com a seguinte fórmula: <code>=A1 * A2</code>
        </li>
      </ol>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    selectedYearRange: {
      type: Object,
      required: true,
    },
  },
  methods: {
    generateFormula(taxBrackets, value) {
      let formula = "=IFS(";
      for (let i = 0; i < taxBrackets.length; i++) {
        let upperLimit = taxBrackets[i].upperLimit === Infinity ? "1E+307" : taxBrackets[i].upperLimit;
        formula += `A1<=${upperLimit.toLocaleString("pt-BR", {useGrouping: false})}; ${taxBrackets[i][value].toLocaleString("pt-BR", {useGrouping: false})}`;
        if (i !== taxBrackets.length - 1) {
          formula += "; ";
        }
      }
      formula += ")";
      return formula;
    },
    generateFormulaCompact(taxBrackets, value, salaryCell = 'A1') {
      let formula = "IFS(";
      for (let i = 0; i < taxBrackets.length; i++) {
        let upperLimit = taxBrackets[i].upperLimit === Infinity ? "1E+307" : taxBrackets[i].upperLimit;
        formula += `${salaryCell}<=${upperLimit.toLocaleString("pt-BR", {useGrouping: false})}; ${taxBrackets[i][value].toLocaleString("pt-BR", {useGrouping: false})}`;
        if (i !== taxBrackets.length - 1) {
          formula += "; ";
        }
      }
      formula += ")";
      return formula;
    },
  },
};
</script>
