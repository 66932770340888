// validationMethods.js

export const validateGrossIncome = (monthlyGrossIncome) => {
    if (monthlyGrossIncome === '' || monthlyGrossIncome === 0) return '';
    return monthlyGrossIncome >= 0 ? '' : 'Valor deve ser positivo e maior que zero';
  };
  
  export const validateDependents = (dependents) => {
    if (dependents === '' || dependents === 0) return '';
    return dependents >= 0 && dependents <= 50 ? '' : 'Valor deve ser entre 0 e 50';
  };
  