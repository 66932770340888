// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showPdfButton: false,
  },
  mutations: {
    setShowPdfButton(state, value) {
      state.showPdfButton = value;
    },
  },
});
