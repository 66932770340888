<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>
        <v-btn text class="toolbar-title-link" @click="goToHome">
          Calculadoras Online
        </v-btn>
      </v-toolbar-title>
      
      <v-spacer></v-spacer>
      
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn text @click="goToIRRF">IRRF</v-btn>
        <v-btn text @click="goToINSS">INSS</v-btn>
        <v-btn text @click="goToAbout">Sobre</v-btn>
      </div>
      
      <v-spacer></v-spacer>

      <PdfDownload targetClass=".displayOnPDF" />

      <v-spacer></v-spacer>
      
      <ShareButtons :share-message="customShareMessage" />
      
      <v-switch v-model="isDarkMode" label="Modo escuro" @change="toggleDarkMode" class="mx-4" hide-details></v-switch>
      
      <v-btn icon @click="drawer = !drawer" v-if="!$vuetify.breakpoint.mdAndUp">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" :temporary="!$vuetify.breakpoint.mdAndUp">
      <v-list>
        <v-list-item link @click="goToHome">
          <v-list-item-title>Principal</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="goToIRRF">
          <v-list-item-title>IRRF</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="goToINSS">
          <v-list-item-title>INSS</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="goToAbout">
          <v-list-item-title>Sobre</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ShareButtons from '@/components/ShareButtons.vue';
import PdfDownload from '@/components/PdfDownload.vue';

export default {
  components: {
    ShareButtons,
    PdfDownload,
  },
  data() { 
    return {
      drawer: false,
      isDarkMode: false,
      customShareMessage: "Confira esse site de calculadoras, com essa ótima Calculadora de Imposto de Renda Retido na Fonte (IRRF)!"
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: "Home" });
      this.drawer = false;
    },
    goToIRRF() {
      this.$router.push({ name: "IRRF" });
      this.drawer = false;
    },
    goToINSS() {
      this.$router.push({ name: "INSS" });
      this.drawer = false;
    },
    goToAbout() {
      this.$router.push({ name: "About" });
      this.drawer = false;
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = this.isDarkMode;
      localStorage.setItem("darkMode", this.isDarkMode);
    },
  },
  created() {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode) {
      this.isDarkMode = JSON.parse(savedDarkMode);
      this.$vuetify.theme.dark = this.isDarkMode;
    }
  },
  watch: {
    '$vuetify.breakpoint.width'(newWidth, oldWidth) {
      if (newWidth >= this.$vuetify.breakpoint.thresholds.md && oldWidth < this.$vuetify.breakpoint.thresholds.md) {
        this.drawer = false;
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title-link {
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  min-width: 0;
  text-transform: none;
}

@media (max-width: 600px) {
  .toolbar-title-link {
    font-size: 1.25rem;
  }
}
</style>
<style>
.error-message {
  color: red;
}
</style>
