<template>
  <v-row>
    <v-col cols="12">
      <h3>Ajuda e Perguntas Frequentes (FAQ)</h3>
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, index) in faqItems" :key="index">
          <v-expansion-panel-header>{{ item.question }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ item.answer }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FaqList',
  
  props: {
    faqItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
  