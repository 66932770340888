<template>
  <v-row>
    <v-col cols="12">
      <h3>Como calcular manualmente o INSS para esse período:</h3>
      <ol>
        <li>Com o salário mensal bruto, encontre a faixa correspondente na tabela INSS e aplique a alíquota sobre o valor obtido.</li>
        <li>O resultado do passo 1 é o valor do INSS a ser retido.</li>
      </ol>
    </v-col>
  </v-row>
</template>
  
<script>
export default {
};
</script>