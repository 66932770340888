import Vue from 'vue';
import VueRouter from 'vue-router';

import IRRF from '@/views/irrf/MainView.vue';
import INSS from '@/views/inss/MainView.vue';
import About from '@/views/about/AboutView.vue';
import Home from '@/views/home/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/irrf',
    name: 'IRRF',
    component: IRRF,
  },
  {
    path: '/inss',
    name: 'INSS',
    component: INSS,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
