// faqItems.js

export const FAQ_ITEMS = [
  {
    question: 'O que é IRRF?',
    answer: 'IRRF (Imposto de Renda Retido na Fonte) é um imposto que é descontado diretamente do salário do trabalhador. É uma antecipação do pagamento do Imposto de Renda devido pelo contribuinte.',
  },
  {
    question: 'Como é calculado o IRRF?',
    answer: 'O IRRF é calculado com base no salário bruto do trabalhador, levando em consideração o número de dependentes e a tabela progressiva do Imposto de Renda. O cálculo é feito subtraindo do salário bruto o valor referente aos dependentes, aplicando a alíquota correspondente e subtraindo a parcela a deduzir.',
  },
  {
    question: 'Quem deve pagar o IRRF?',
    answer: 'O IRRF é obrigatório para trabalhadores que recebem um salário bruto acima do limite de isenção estabelecido pela tabela progressiva do Imposto de Renda.',
  },
  {
    question: 'Qual é o valor do desconto por dependente?',
    answer: 'O valor do desconto por dependente pode variar de acordo com a tabela de IRRF vigente. Atualmente, o desconto é de R$ 189,59 por dependente.',
  },
  {
    question: 'Como são determinados os limites de isenção e as alíquotas?',
    answer: 'Os limites de isenção e as alíquotas são estabelecidos pela Receita Federal e podem ser ajustados periodicamente. A tabela progressiva do Imposto de Renda é atualizada de acordo com essas mudanças.',
  },
  {
    question: 'Qual é a diferença entre IRRF e IRPF?',
    answer: 'O IRRF (Imposto de Renda Retido na Fonte) é um adiantamento do Imposto de Renda devido pelo contribuinte, retido diretamente na fonte pagadora. Já o IRPF (Imposto de Renda Pessoa Física) é o imposto devido pelo contribuinte pessoa física, calculado com base em seus rendimentos e descontos anuais.',
  },
  {
    question: 'Como posso corrigir o IRRF retido incorretamente?',
    answer: 'Se você acredita que o IRRF foi retido incorretamente, entre em contato com o departamento de recursos humanos ou financeiro da sua empresa para corrigir o erro. Caso o erro não seja corrigido, você pode ajustar o valor na declaração de Imposto de Renda.',
  },
  {
    question: 'O que acontece se eu tiver um IRRF retido a maior?',
    answer: 'Se você tiver um IRRF retido a maior, poderá recuperar o valor na sua declaração de Imposto de Renda. O valor retido a maior será considerado como um crédito e poderá serutilizado para abater o valor do Imposto de Renda devido ou, se houver saldo remanescente, ser restituído pelo governo.',
  },
  {
    question: 'Como devo declarar o IRRF na minha declaração de Imposto de Renda?',
    answer: 'O IRRF deve ser informado na sua declaração de Imposto de Renda. Geralmente, a empresa fornecerá um informe de rendimentos contendo os valores retidos na fonte. Utilize essas informações para preencher a declaração. O valor do IRRF será utilizado para abater o valor total do Imposto de Renda devido.',
  },
  {
    question: 'Quais são os rendimentos isentos de IRRF?',
    answer: 'Alguns rendimentos são isentos do IRRF, como o salário-família, o salário-maternidade, aposentadoria, pensão e reserva remunerada de militares com mais de 65 anos, entre outros. Consulte a legislação vigente e a tabela progressiva do Imposto de Renda para obter informações detalhadas sobre isenções e alíquotas.',
  },
];