<template>
  <v-row>
    <v-col cols="12" md="8">
      <h1>Calculadora de Imposto de Renda Retido na Fonte (IRRF)</h1>
      <p>
        Esta é uma ferramenta simples e fácil de usar para calcular o
        Imposto de Renda Retido na Fonte (IRRF). Selecione a tabela
        IRRF pelo período do cálculo, informe o salário mensal bruto e o
        número de dependentes para obter o valor do imposto.
      </p>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="selectedYearRange"
            :items="yearRanges"
            item-text="label"
            label="Selecione a tabela IRRF a utilizar"
            @change="debounceCalculateIRRF"
            return-object
          >
            <template v-slot:append>
              <TooltipHelp tooltipText="Escolha a tabela IRRF com base no período do cálculo." />
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="monthlyGrossIncome"
            v-money="money"
            label="Salário Mensal Bruto"
            type="text"
            :error-messages="validateGrossIncome"
            @input="debounceCalculateIRRF"
          >
            <template v-slot:append>
              <TooltipHelp tooltipText="Informe o valor do salário bruto recebido mensalmente." />
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="dependents"
            label="Número de Dependentes"
            type="number"
            :error-messages="validateDependents"
            @input="debounceCalculateIRRF"
          >
            <template v-slot:append>
              <TooltipHelp tooltipText="Informe a quantidade de dependentes para dedução no cálculo do IRRF." />
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="irrf !== null" class="displayOnPDF">
        <v-col cols="12">
          <h2>
            Dado um salario mensal bruto de {{ monthlyGrossIncome }}
            com {{ dependents }} dependentes.<br />
            O IRRF mensal será de: {{ formatCurrency(irrf) }}</h2>
        </v-col>
      </v-row>

      <v-row v-if="errorMessage" class="displayOnPDF">
        <v-col cols="12">
          <h2 class="error-message">{{ errorMessage }}</h2>
        </v-col>
      </v-row>

      <FormulasAndTable
        :dependentValue="selectedYearRange.dependentValue"
        :label="selectedYearRange.label"
        :ranges="selectedYearRange.ranges"
      ></FormulasAndTable>

      <ExcelInstructions :selected-year-range="selectedYearRange"></ExcelInstructions>

      <ManualInstructions :selected-year-range="selectedYearRange"></ManualInstructions>

      <FaqList :faq-items="faqItems" />

      <AboutDescription></AboutDescription>
    </v-col>

    <AdZonePlaceholder></AdZonePlaceholder>
  </v-row>
</template>

<script>
import { VMoney } from "v-money";
import { formatCurrency, debounce, convertMoneyToNumber } from "@/utils";
import { validateGrossIncome, validateDependents } from '@/validationMethods.js';

import { YEAR_RANGES } from './yearRanges.js';
import { FAQ_ITEMS } from './faqItems.js';
import { META } from './metaData.js';

import * as components from './components.js';

export default {
  name: "IRRFView",
  metaInfo: META,
  directives: {
    money: VMoney,
  },
  components: {
    ...components
  },
  data() { 
    return {
      faqItems: FAQ_ITEMS,
      monthlyGrossIncome: 0,
      dependents: 0,
      irrf: null,
      errorMessage: null,
      yearRanges: YEAR_RANGES,
      selectedYearRange: YEAR_RANGES[0],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
    };
  },
  computed: {
    validateGrossIncome() {
      return validateGrossIncome(convertMoneyToNumber(this.monthlyGrossIncome));
    },
    validateDependents() {
      return validateDependents(this.dependents);
    },
  },
  methods: {
    formatCurrency,
    calculateIRRF() {
      this.$store.commit('setShowPdfButton', false);
      this.irrf = null;
      this.errorMessage = null;

      if (convertMoneyToNumber(this.monthlyGrossIncome) <= 0 || this.dependents < 0 || this.dependents > 50) {
        return;
      }

      const dependentsDeduction = this.selectedYearRange.dependentValue * this.dependents;
      const baseValue = convertMoneyToNumber(this.monthlyGrossIncome) - dependentsDeduction;

      let baseRange = this.selectedYearRange.ranges.find(bracket => baseValue <= bracket.upperLimit || bracket === this.selectedYearRange.ranges[this.selectedYearRange.ranges.length - 1]);
      const taxRate = baseRange.taxRate;
      const deductableValue = baseRange.deductableValue;

      this.irrf = baseValue * taxRate - deductableValue;
      this.$store.commit('setShowPdfButton', this.irrf !== null);
    },
    debounceCalculateIRRF: debounce(function () {
      this.calculateIRRF();
    }, 500),
  },
};
</script>