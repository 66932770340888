<template>
  <v-row>
    <v-col cols="12">
      <h3>Sobre a Calculadora de Contribuição ao INSS</h3>
      <p>
        A Calculadora de Contribuição ao INSS é uma ferramenta online desenvolvida
        para ajudar os trabalhadores brasileiros a calcular o valor da contribuição
        previdenciária com base no salário mensal bruto.
      </p>
      <p>
        O objetivo desta aplicação é facilitar o processo de cálculo da contribuição
        ao INSS e ajudar os trabalhadores a entender melhor suas obrigações previdenciárias.
        Por favor, note que esta ferramenta não substitui a orientação de um contador ou
        profissional de previdência qualificado.
      </p>
      <p>
        O uso desta ferramenta é gratuito e livre para todos os usuários. No entanto,
        não nos responsabilizamos por quaisquer danos causados pelo uso desta aplicação.
        Recomendamos sempre consultar um contador ou profissional de previdência qualificado
        para garantir a precisão e a conformidade com as leis previdenciárias aplicáveis.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
};
</script>
