// yearRanges.js

export const YEAR_RANGES = [
  {
    label: '01/2023 - 12/2023',
    start: new Date('2023-01-01'),
    end: new Date('2023-12-31'),
    ranges: [
      { upperLimit: 1302.00, taxRate: 0.075 },
      { upperLimit: 2571.29, taxRate: 0.09 },
      { upperLimit: 3856.94, taxRate: 0.12 },
      { upperLimit: 7507.49, taxRate: 0.14 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2022 - 12/2022',
    start: new Date('2022-01-01'),
    end: new Date('2022-12-31'),
    ranges: [
      { upperLimit: 1212.00, taxRate: 0.075 },
      { upperLimit: 2427.35, taxRate: 0.09 },
      { upperLimit: 3641.03, taxRate: 0.12 },
      { upperLimit: 7087.22, taxRate: 0.14 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2021 - 12/2021',
    start: new Date('2021-01-01'),
    end: new Date('2021-12-31'),
    ranges: [
      { upperLimit: 1100.00, taxRate: 0.075 },
      { upperLimit: 2203.48, taxRate: 0.09 },
      { upperLimit: 3305.22, taxRate: 0.12 },
      { upperLimit: 6433.57, taxRate: 0.14 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '03/2020 - 12/2020',
    start: new Date('2020-03-01'),
    end: new Date('2020-12-31'),
    ranges: [
      { upperLimit: 1045.00, taxRate: 0.075 },
      { upperLimit: 2089.60, taxRate: 0.09 },
      { upperLimit: 3134.40, taxRate: 0.12 },
      { upperLimit: 6101.06, taxRate: 0.14 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2020 - 02/2020',
    start: new Date('2020-01-01'),
    end: new Date('2020-02-29'),
    ranges: [
      { upperLimit: 1830.29, taxRate: 0.08 },
      { upperLimit: 3050.52, taxRate: 0.09 },
      { upperLimit: 6101.06, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2019 - 12/2019',
    start: new Date('2019-01-01'),
    end: new Date('2019-12-31'),
    ranges: [
      { upperLimit: 1751.81, taxRate: 0.08 },
      { upperLimit: 2919.72, taxRate: 0.09 },
      { upperLimit: 5839.45, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2018 - 12/2018',
    start: new Date('2018-01-01'),
    end: new Date('2018-12-31'),
    ranges: [
      { upperLimit: 1693.72, taxRate: 0.08 },
      { upperLimit: 2822.90, taxRate: 0.09 },
      { upperLimit: 5645.80, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2017 - 12/2017',
    start: new Date('2017-01-01'),
    end: new Date('2017-12-31'),
    ranges: [
      { upperLimit: 1659.38, taxRate: 0.08 },
      { upperLimit: 2765.66, taxRate: 0.09 },
      { upperLimit: 5531.31, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2016 - 12/2016',
    start: new Date('2016-01-01'),
    end: new Date('2016-12-31'),
    ranges: [
      { upperLimit: 1556.94, taxRate: 0.08 },
      { upperLimit: 2594.92, taxRate: 0.09 },
      { upperLimit: 5189.82, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2015 - 12/2015',
    start: new Date('2015-01-01'),
    end: new Date('2015-12-31'),
    ranges: [
      { upperLimit: 1399.12, taxRate: 0.08 },
      { upperLimit: 2331.88, taxRate: 0.09 },
      { upperLimit: 4663.75, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  /*{
    label: '01/2014 - 12/2014',
    start: new Date('2014-01-01'),
    end: new Date('2014-12-31'),
    ranges: [
      { upperLimit: 1317.07, taxRate: 0.08 },
      { upperLimit: 2195.12, taxRate: 0.09 },
      { upperLimit: 4390.24, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2013 - 12/2013',
    start: new Date('2013-01-01'),
    end: new Date('2013-12-31'),
    ranges: [
      { upperLimit: 1247.70, taxRate: 0.08 },
      { upperLimit: 2079.50, taxRate: 0.09 },
      { upperLimit: 4159.00, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2012 - 12/2012',
    start: new Date('2012-01-01'),
    end: new Date('2012-12-31'),
    ranges: [
      { upperLimit: 1174.86, taxRate: 0.08 },
      { upperLimit: 1958.10, taxRate: 0.09 },
      { upperLimit: 3916.20, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '07/2011 - 12/2011',
    start: new Date('2011-07-01'),
    end: new Date('2011-12-31'),
    ranges: [
      { upperLimit: 1107.52, taxRate: 0.08 },
      { upperLimit: 1845.87, taxRate: 0.09 },
      { upperLimit: 3691.74, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2011 - 06/2011',
    start: new Date('2011-01-01'),
    end: new Date('2011-06-30'),
    ranges: [
      { upperLimit: 1106.90, taxRate: 0.08 },
      { upperLimit: 1844.83, taxRate: 0.09 },
      { upperLimit: 3689.66, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '06/2010 - 12/2010',
    start: new Date('2010-06-01'),
    end: new Date('2010-12-31'),
    ranges: [
      { upperLimit: 1040.22, taxRate: 0.08 },
      { upperLimit: 1733.70, taxRate: 0.09 },
      { upperLimit: 3467.40, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2010 - 01/2010',
    start: new Date('2010-01-01'),
    end: new Date('2010-01-31'),
    ranges: [
      { upperLimit: 1024.97, taxRate: 0.08 },
      { upperLimit: 1708.27, taxRate: 0.09 },
      { upperLimit: 3416.24, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '02/2009 - 12/2009',
    start: new Date('2009-02-01'),
    end: new Date('2009-12-31'),
    ranges: [
      { upperLimit: 965.67, taxRate: 0.08 },
      { upperLimit: 1609.45, taxRate: 0.09 },
      { upperLimit: 3218.90, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '03/2008 - 12/2008',
    start: new Date('2008-03-01'),
    end: new Date('2008-12-31'),
    ranges: [
      { upperLimit: 911.70, taxRate: 0.08 },
      { upperLimit: 1519.50, taxRate: 0.09 },
      { upperLimit: 3038.99, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2008 - 02/2008',
    start: new Date('2008-01-01'),
    end: new Date('2008-02-29'),
    ranges: [
      { upperLimit: 868.29, taxRate: 0.08 },
      { upperLimit: 1447.14, taxRate: 0.09 },
      { upperLimit: 2894.28, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '04/2007 - 12/2007',
    start: new Date('2007-04-01'),
    end: new Date('2007-12-31'),
    ranges: [
      { upperLimit: 868.29, taxRate: 0.0765 },
      { upperLimit: 1140.00, taxRate: 0.0865 },
      { upperLimit: 1447.14, taxRate: 0.09 },
      { upperLimit: 2894.28, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '08/2006 - 03/2006',
    start: new Date('2006-08-01'),
    end: new Date('2006-03-31'),
    ranges: [
      { upperLimit: 840.55, taxRate: 0.0765 },
      { upperLimit: 1050.00, taxRate: 0.0865 },
      { upperLimit: 1400.91, taxRate: 0.09 },
      { upperLimit: 2801.82, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '04/2006 - 07/2006',
    start: new Date('2006-04-01'),
    end: new Date('2006-07-31'),
    ranges: [
      { upperLimit: 840.47, taxRate: 0.0765 },
      { upperLimit: 1050.00, taxRate: 0.0865 },
      { upperLimit: 1400.77, taxRate: 0.09 },
      { upperLimit: 2801.56, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '05/2005 - 03/2006',
    start: new Date('2005-05-01'),
    end: new Date('2006-03-31'),
    ranges: [
      { upperLimit: 800.45, taxRate: 0.0765 },
      { upperLimit: 900.00, taxRate: 0.0865 },
      { upperLimit: 1334.07, taxRate: 0.09 },
      { upperLimit: 2668.15, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '05/2004 - 04/2005',
    start: new Date('2004-05-01'),
    end: new Date('2005-04-30'),
    ranges: [
      { upperLimit: 752.62, taxRate: 0.0765 },
      { upperLimit: 780.00, taxRate: 0.0865 },
      { upperLimit: 1254.36, taxRate: 0.09 },
      { upperLimit: 2508.72, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '01/2004 - 04/2004',
    start: new Date('2004-01-01'),
    end: new Date('2004-04-30'),
    ranges: [
      { upperLimit: 720.00, taxRate: 0.0765 },
      { upperLimit: 1200.00, taxRate: 0.09 },
      { upperLimit: 2400.00, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '06/2003 - 03/2003',
    start: new Date('2003-06-01'),
    end: new Date('2003-03-31'),
    ranges: [
      { upperLimit: 560.81, taxRate: 0.0765 },
      { upperLimit: 720.00, taxRate: 0.0865 },
      { upperLimit: 934.67, taxRate: 0.09 },
      { upperLimit: 1869.34, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '04/2003 - 05/2003',
    start: new Date('2003-04-01'),
    end: new Date('2003-05-31'),
    ranges: [
      { upperLimit: 468.47, taxRate: 0.0765 },
      { upperLimit: 720.00, taxRate: 0.0865 },
      { upperLimit: 780.78, taxRate: 0.09 },
      { upperLimit: 1561.56, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '06/2002 - 03/2003',
    start: new Date('2002-06-01'),
    end: new Date('2003-03-31'),
    ranges: [
      { upperLimit: 468.47, taxRate: 0.0765 },
      { upperLimit: 600.00, taxRate: 0.0865 },
      { upperLimit: 780.78, taxRate: 0.09 },
      { upperLimit: 1561.56, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '04/2002 - 05/2002',
    start: new Date('2002-04-01'),
    end: new Date('2002-05-31'),
    ranges: [
      { upperLimit: 429.00, taxRate: 0.0765 },
      { upperLimit: 600.00, taxRate: 0.0865 },
      { upperLimit: 715.00, taxRate: 0.09 },
      { upperLimit: 1430.00, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '06/2001 - 03/2001',
    start: new Date('2001-06-01'),
    end: new Date('2001-03-31'),
    ranges: [
      { upperLimit: 429.00, taxRate: 0.0765 },
      { upperLimit: 540.00, taxRate: 0.0865 },
      { upperLimit: 715.00, taxRate: 0.09 },
      { upperLimit: 1430.00, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '04/2001 - 05/2001',
    start: new Date('2001-04-01'),
    end: new Date('2001-05-31'),
    ranges: [
      { upperLimit: 398.48, taxRate: 0.0765 },
      { upperLimit: 540.00, taxRate: 0.0865 },
      { upperLimit: 664.13, taxRate: 0.09 },
      { upperLimit: 1328.25, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '03/2001',
    start: new Date('2001-03-01'),
    end: new Date('2001-03-31'),
    ranges: [
      { upperLimit: 398.48, taxRate: 0.0765 },
      { upperLimit: 453.00, taxRate: 0.0865 },
      { upperLimit: 664.13, taxRate: 0.09 },
      { upperLimit: 1328.25, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '07/2000 - 06/2000',
    start: new Date('2000-07-01'),
    end: new Date('2000-06-30'),
    ranges: [
      { upperLimit: 398.48, taxRate: 0.0772 },
      { upperLimit: 453.00, taxRate: 0.0873 },
      { upperLimit: 664.13, taxRate: 0.09 },
      { upperLimit: 1328.25, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },
  {
    label: '06/2000',
    start: new Date('2000-06-01'),
    end: new Date('2000-06-30'),
    ranges: [
      { upperLimit: 398.48, taxRate: 0.0772 },
      { upperLimit: 453.00, taxRate: 0.0873 },
      { upperLimit: 664.13, taxRate: 0.09 },
      { upperLimit: 1328.25, taxRate: 0.11 },
      { upperLimit: Infinity, taxRate: 0 },
    ],
  },*/
];