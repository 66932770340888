// faqItems.js

export const FAQ_ITEMS = [
  {
    question: 'O que é INSS?',
    answer: 'INSS (Instituto Nacional do Seguro Social) é uma autarquia do governo brasileiro responsável pela gestão do Regime Geral da Previdência Social. O INSS é uma contribuição previdenciária que garante benefícios como aposentadoria, auxílio-doença, salário-maternidade e pensão por morte, entre outros.',
  },
  {
    question: 'Como é calculado o INSS?',
    answer: 'O INSS é calculado com base no salário bruto do trabalhador, aplicando-se a alíquota correspondente conforme a tabela de contribuição previdenciária vigente. As alíquotas variam de acordo com a faixa salarial do contribuinte.',
  },
  {
    question: 'Quem deve pagar o INSS?',
    answer: 'O INSS é obrigatório para trabalhadores com carteira assinada, servidores públicos, trabalhadores avulsos, contribuintes individuais (autônomos e profissionais liberais) e facultativos (pessoas sem renda própria que desejam contribuir para a Previdência Social).',
  },
  {
    question: 'Qual é a alíquota do INSS?',
    answer: 'A alíquota do INSS varia de acordo com a faixa salarial do contribuinte, conforme a tabela de contribuição previdenciária vigente. As alíquotas podem ser de 7,5%, 9%, 12% ou 14%.',
  },
  {
    question: 'Como são determinados os limites e as alíquotas de contribuição?',
    answer: 'Os limites e as alíquotas de contribuição são estabelecidos pelo governo federal e podem ser ajustados periodicamente. A tabela de contribuição previdenciária é atualizada de acordo com essas mudanças.',
  },
  {
    question: 'O que acontece se eu não pagar o INSS?',
    answer: 'O não pagamento do INSS pode resultar em multas e juros, além de impedir o acesso aos benefícios previdenciários. No caso de trabalhadores com carteira assinada, é responsabilidade da empresa efetuar o recolhimento do INSS. Já os contribuintes individuais e facultativos devem efetuar o pagamento diretamente à Previdência Social.',
  },
  {
    question: 'Posso pagar o INSS atrasado?',
    answer: 'Sim, é possível pagar o INSS atrasado. No entanto, o contribuinte estará sujeito a multas e juros. Para regularizar a situação, é necessário gerar uma Guia da Previdência Social (GPS) com os valores devidos e realizar o pagamento.',
  },
  {
    question: 'Como posso consultar meu extrato de contribuições ao INSS?',
    answer: 'Você pode consultar seu extrato de contribuições ao INSS pelo portal Meu INSS (meu.inss.gov.br) ou pelo aplicativo Meu INSS, disponível para smartphones e tablets. É necessário realizar um cadastro e, em seguida, acessar a opção "Extrato Previdenciário" para verificar as informações.',
  },
  {
    question: 'Quais são os benefícios garantidos pelo INSS?',
    answer: 'Os benefícios garantidos pelo INSS incluem aposentadoria por idade, aposentadoria por tempo de contribuição, aposentadoria por invalidez, auxílio-doença, auxílio-acidente, salário-maternidade, pensão por morte e auxílio-reclusão, entre outros. Esses benefícios têm como objetivo garantir proteção e amparo aos trabalhadores e suas famílias em momentos de necessidade.',
  },
  {
    question: 'Como faço para requerer um benefício do INSS?',
    answer: 'Para requerer um benefício do INSS, você deve acessar o portal Meu INSS (meu.inss.gov.br) ou utilizar o aplicativo Meu INSS, disponível para smartphones e tablets. Após realizar o cadastro e acessar sua conta, selecione a opção "Agendamentos/Requerimentos" e siga as instruções para solicitar o benefício desejado. Em alguns casos, pode ser necessário apresentar documentos adicionais ou comparecer a uma agência da Previdência Social.',
  },
  {
    question: 'O que é o teto do INSS?',
    answer: 'O teto do INSS é o valor máximo de contribuição e de benefícios pagos pela Previdência Social. Ele é atualizado periodicamente, geralmente a cada ano, e serve como referência para o cálculo das contribuições e dos benefícios previdenciários. A contribuição ao INSS é limitada ao teto, mesmo que o salário do trabalhador seja superior a esse valor.',
  },
];