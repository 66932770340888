<template>
  <v-row>
    <v-col cols="12">
      <h3>Sobre a Calculadora de Imposto de Renda Retido na Fonte (IRRF)</h3>
      <p>
        A Calculadora de Imposto de Renda Retido na Fonte (IRRF) é uma
        ferramenta online desenvolvida para ajudar os contribuintes brasileiros
        a calcular o valor do imposto retido na fonte com base no salário mensal
        bruto e no número de dependentes.
      </p>
      <p>
        O objetivo desta aplicação é facilitar o processo de cálculo do IRRF e
        ajudar os contribuintes a entender melhor suas obrigações fiscais. Por
        favor, note que esta ferramenta não substitui a orientação de um
        contador ou profissional de impostos qualificado.
      </p>
      <p>
        O uso desta ferramenta é gratuito e livre para todos os usuários. No
        entanto, não nos responsabilizamos por quaisquer danos causados pelo
        uso desta aplicação. Recomendamos sempre consultar um contador ou
        profissional de impostos qualificado para garantir a precisão e a
        conformidade com as leis fiscais aplicáveis.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
};
</script>