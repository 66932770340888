<template>
  <v-btn v-if="showPdfButton" @click="downloadPdf" color="secondary">
    <v-icon>mdi-download</v-icon>
    Baixar Resultado em PDF
  </v-btn>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
  name: 'PdfDownload',
  props: {
    targetClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    showPdfButton() {
      return this.$store.state.showPdfButton;
    }
  },
  methods: {
    async downloadPdf() {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const displayOnPdfElements = document.querySelectorAll(this.targetClass);

      const container = document.createElement('div');
      container.style.color = 'black';
      container.style.backgroundColor = 'white';
      container.style.padding = '10px';
      container.style.width = '100%';

      displayOnPdfElements.forEach((element) => {
        const clonedElement = element.cloneNode(true);
        container.appendChild(clonedElement);
      });

      document.body.appendChild(container);

      const canvas = await html2canvas(container, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      document.body.removeChild(container);
      pdf.save('online-calculation.pdf');
    },
  },
  watch: {
    $route() {
      this.$store.commit('setShowPdfButton', false);
    }
  }
};
</script>