<template>
  <div class="ad-placeholder" :style="{ height: height }">{{ content }}</div>
</template>

<script>
export default {
  name: 'AdPlaceholder',
  
  props: {
    content: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.ad-placeholder {
  border: 1px dashed #999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
</style>
  