export const META = {
  title: "| Calculadora de INSS",
  meta: [
    {
      name: "description",
      content: "Use nossa Calculadora de INSS (Instituto Nacional do Seguro Social) para calcular facilmente o valor da contribuição com base em seu salário.",
    },
    {
      name: "keywords",
      content: "calculadora, INSS, seguro social, contribuição, online, 2023, salário",
    },
  ],
  script: [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Calculadora de INSS",
        "description": "Esta é uma ferramenta simples e fácil de usar para calcular a contribuição do INSS com base no salário mensal bruto.",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.calculadorasonline.com.br/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "INSS",
              "item": "https://www.calculadorasonline.com.br/inss"
            }
          ]
        }
      },
    }
  ],
};
