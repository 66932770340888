export const META = {
  title: "| Calculadora de IRRF",
  meta: [
    {
      name: "description",
      content: "Use nossa Calculadora de IRRF (Imposto de Renda Retido na Fonte) para calcular facilmente o valor do imposto retido na fonte com base em seu salário e deduções.",
    },
    {
      name: "keywords",
      content: "calculadora, IRRF, imposto, renda, retido, fonte, online, excel, 2023, salário, deduções",
    },
  ],
  script: [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Calculadora de Imposto de Renda Retido na Fonte (IRRF)",
        "description": "Esta é uma ferramenta simples e fácil de usar para calcular o Imposto de Renda Retido na Fonte (IRRF). Selecione a tabela IRRF pelo período do cálculo, informe o salário mensal bruto e o número de dependentes para obter o valor do imposto.",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.calculadorasonline.com.br/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "IRRF",
              "item": "https://www.calculadorasonline.com.br/irrf"
            }
          ]
        }
      },
    }
  ],
};
  