<template>
  <v-col cols="12" md="4">
    <!-- Área de anúncios -->
    <ad-placeholder content="Espaço para anúncio" height="100px" />
    <ad-placeholder content="Espaço para anúncio" height="100px" />
    <ad-placeholder content="Espaço para anúncio" height="100px" />
  </v-col>
</template>

<script>
import AdPlaceholder from '@/components/AdPlaceholder.vue';

export default {
  name: 'AdZonePlaceholder',
  components: {
    AdPlaceholder
  }
};
</script>
  