export const META = {
  title: "| Home",
  meta: [
    {
      name: "description",
      content: "Calculadoras Online, um site que oferece calculadoras úteis e fáceis de usar para IRRF, INSS e muito mais."
    },
    {
      name: "keywords",
      content: "calculadoras, IRRF, INSS, online, excel, 2023, imposto, renda, seguridade social"
    }
  ],
  script: [
    {
      type: "application/ld+json",
      json: {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Calculadoras Online",
        "description": "Calculadoras Online, um site que oferece calculadoras úteis e fáceis de usar para IRRF, INSS e muito mais.",
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.calculadorasonline.com.br/"
            },
          ]
        }
      },
    }
  ],
};
