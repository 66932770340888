<template>
  <v-row>
    <v-col cols="12">
      <h3>Como calcular o IRRF no Excel para o período {{ selectedYearRange.label }}:</h3>
      <p>
        Para calcular o IRRF no Excel, siga as etapas abaixo. As fórmulas já incluem os valores da tabela IRRF selecionada.
      </p>
      <h4>Versão compacta:</h4>
      <ol>
        <li>Crie uma célula para o salário mensal bruto (exemplo: A1).</li>
        <li>Crie uma célula para a quantidade de dependentes (exemplo: A2).</li>
        <li>
          Calcule o valor do IRRF em uma única fórmula, utilizando apenas as células A1 e A2:
          <code>
          =((A1 - (A2 * {{ selectedYearRange.dependentValue.toLocaleString("pt-BR", {useGrouping: false}) }})) * {{ generateFormulaCompact(selectedYearRange.ranges, 'taxRate', 'A1', 'A2') }}) - {{ generateFormulaCompact(selectedYearRange.ranges, 'deductableValue', 'A1', 'A2') }}
          </code>
        </li>
      </ol>
      <h4>Versão extendida:</h4>
      <ol>
        <li>Crie uma célula para o salário mensal bruto (exemplo: A1).</li>
        <li>Crie uma célula para a quantidade de dependentes (exemplo: A2).</li>
        <li>Calcule o valor a ser deduzido por dependentes em uma célula (exemplo: A3) com a fórmula: <code>=A2 * {{ selectedYearRange.dependentValue.toLocaleString("pt-BR", {useGrouping: false}) }}</code></li>
        <li>Calcule o valor base em uma célula (exemplo: A4) com a fórmula: <code>=A1 - A3</code></li>
        <li>
          Crie uma célula (por exemplo, A5) e insira a seguinte fórmula para encontrar a alíquota correspondente ao valor base, utilizando os valores da tabela IRRF selecionada:
          <code>
          {{ generateFormula(selectedYearRange.ranges, 'taxRate') }}
          </code>
        </li>
        <li>
          Crie uma célula (por exemplo, A6) e insira a seguinte fórmula para encontrar a parcela a deduzir, utilizando os valores da tabela IRRF selecionada:
          <code>
          {{ generateFormula(selectedYearRange.ranges, 'deductableValue') }}
          </code>
        </li>
        <li>
          Calcule o valor do IRRF em uma célula (por exemplo, A7) com a seguinte fórmula: <code>=A4 * A5 - A6</code>
        </li>
      </ol>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    selectedYearRange: {
      type: Object,
      required: true,
    },
  },
  methods: {
    generateFormula(ranges, value) {
      let formula = "=IFS(";
      for (let i = 0; i < ranges.length; i++) {
        let upperLimit = ranges[i].upperLimit === Infinity ? "1E+307" : ranges[i].upperLimit;
        formula += `A4<=${upperLimit.toLocaleString("pt-BR", {useGrouping: false})}; ${ranges[i][value].toLocaleString("pt-BR", {useGrouping: false})}`;
        if (i !== ranges.length - 1) {
          formula += "; ";
        }
      }
      formula += ")";
      return formula;
    },
    generateFormulaCompact(ranges, value, salaryCell = 'A4', dependentsCell = 'A2') {
      let formula = "IFS(";
      for (let i = 0; i < ranges.length; i++) {
        let upperLimit = ranges[i].upperLimit === Infinity ? "1E+307" : ranges[i].upperLimit;
        formula += `(${salaryCell} - (${dependentsCell} * ${this.selectedYearRange.dependentValue.toLocaleString("pt-BR", {useGrouping: false})}))<=${upperLimit.toLocaleString("pt-BR", {useGrouping: false})}; ${ranges[i][value].toLocaleString("pt-BR", {useGrouping: false})}`;
        if (i !== ranges.length - 1) {
          formula += "; ";
        }
      }
      formula += ")";
      return formula;
    },
  },
};
</script>