// yearRanges.js

export const YEAR_RANGES = [
  {
    label: '04/2015 - 04/2023',
    start: new Date('2015-04-01'),
    end: new Date('2023-04-01'),
    ranges: [
      { upperLimit: 1903.98, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2826.65, taxRate: 0.075, deductableValue: 142.80 },
      { upperLimit: 3751.05, taxRate: 0.15, deductableValue: 354.80 },
      { upperLimit: 4664.68, taxRate: 0.225, deductableValue: 636.13 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 869.36 },
    ],
    dependentValue: 189.59,
  },
  {
    label: '01/2014 - 03/2015',
    start: new Date('2014-01-01'),
    end: new Date('2015-03-31'),
    ranges: [
      { upperLimit: 1787.77, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2679.29, taxRate: 0.075, deductableValue: 134.08 },
      { upperLimit: 3572.43, taxRate: 0.15, deductableValue: 335.03 },
      { upperLimit: 4463.81, taxRate: 0.225, deductableValue: 602.96 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 826.15 },
    ],
    dependentValue: 179.71,
  },
  {
    label: '01/2013 - 12/2013',
    start: new Date('2013-01-01'),
    end: new Date('2013-12-31'),
    ranges: [
      { upperLimit: 1710.78, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2563.91, taxRate: 0.075, deductableValue: 128.31 },
      { upperLimit: 3418.59, taxRate: 0.15, deductableValue: 320.60 },
      { upperLimit: 4271.59, taxRate: 0.225, deductableValue: 577.00 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 790.58 },
    ],
    dependentValue: 171.97,
  },
  {
    label: '01/2012 - 12/2012',
    start: new Date('2012-01-01'),
    end: new Date('2012-12-31'),
    ranges: [
      { upperLimit: 1637.11, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2453.50, taxRate: 0.075, deductableValue: 122.78 },
      { upperLimit: 3271.38, taxRate: 0.15, deductableValue: 306.80 },
      { upperLimit: 4087.65, taxRate: 0.225, deductableValue: 552.15 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 756.53 },
    ],
    dependentValue: 164.56,
  },
  {
    label: '04/2011 - 12/2011',
    start: new Date('2011-04-01'),
    end: new Date('2011-12-31'),
    ranges: [
      { upperLimit: 1566.61, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2347.85, taxRate: 0.075, deductableValue: 117.49 },
      { upperLimit: 3130.51, taxRate: 0.15, deductableValue: 293.58 },
      { upperLimit: 3911.63, taxRate: 0.225, deductableValue: 528.37 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 723.95 },
    ],
    dependentValue: 157.47,
  },
  {
    label: '01/2010 - 03/2011',
    start: new Date('2010-01-01'),
    end: new Date('2011-03-31'),
    ranges: [
      { upperLimit: 1499.15, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2246.75, taxRate: 0.075, deductableValue: 112.43 },
      { upperLimit: 2995.70, taxRate: 0.15, deductableValue: 280.94 },
      { upperLimit: 3743.19, taxRate: 0.225, deductableValue: 505.62 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 692.78 },
    ],
    dependentValue: 150.69,
  },
  {
    label: '01/2009 - 12/2009',
    start: new Date('2009-01-01'),
    end: new Date('2009-12-31'),
    ranges: [
      { upperLimit: 1434.59, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2150.00, taxRate: 0.075, deductableValue: 107.59 },
      { upperLimit: 2866.70, taxRate: 0.15, deductableValue: 268.84 },
      { upperLimit: 3582.00, taxRate: 0.225, deductableValue: 483.84 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 662.94 },
    ],
    dependentValue: 144.20,
  },
  {
    label: '01/2008 - 12/2008',
    start: new Date('2008-01-01'),
    end: new Date('2008-12-31'),
    ranges: [
      { upperLimit: 1372.81, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2743.25, taxRate: 0.15, deductableValue: 205.92 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 548.82 },
    ],
    dependentValue: 137.99,
  },
  {
    label: '01/2007 - 12/2007',
    start: new Date('2007-01-01'),
    end: new Date('2007-12-31'),
    ranges: [
      { upperLimit: 1313.69, taxRate: 0.00, deductableValue: 0.00 },
      { upperLimit: 2625.12, taxRate: 0.15, deductableValue: 197.05 },
      { upperLimit: Infinity, taxRate: 0.275, deductableValue: 525.19 },
    ],
    dependentValue: 132.05,
  },
  {
    label: '02/2006 - 12/2006',
    start: new Date('2006-02-01'),
    end: new Date('2006-12-31'),
    ranges: [
        { upperLimit: 1257.12, taxRate: 0.00, deductableValue: 0.00 },
        { upperLimit: 2512.08, taxRate: 0.15, deductableValue: 188.57 },
        { upperLimit: Infinity, taxRate: 0.275, deductableValue: 502.58 },
    ],
    dependentValue: 126.36,
  },
  {
    label: '01/2005 - 01/2006',
    start: new Date('2005-01-01'),
    end: new Date('2006-01-31'),
    ranges: [
        { upperLimit: 1164.00, taxRate: 0.00, deductableValue: 0.00 },
        { upperLimit: 2326.00, taxRate: 0.15, deductableValue: 174.60 },
        { upperLimit: Infinity, taxRate: 0.275, deductableValue: 465.35 },
    ],
    dependentValue: 117.00,
  },
  {
    label: '01/2002 - 12/2004',
    start: new Date('2002-01-01'),
    end: new Date('2004-12-31'),
    ranges: [
        { upperLimit: 1058.00, taxRate: 0.00, deductableValue: 0.00 },
        { upperLimit: 2115.00, taxRate: 0.15, deductableValue: 158.70 },
        { upperLimit: Infinity, taxRate: 0.275, deductableValue: 423.08 },
    ],
    dependentValue: 106.00,
  },
  {
    label: '01/1998 - 12/2001',
    start: new Date('1998-01-01'),
    end: new Date('2001-12-31'),
    ranges: [
        { upperLimit: 900.00, taxRate: 0.00, deductableValue: 0.00 },
        { upperLimit: 1800.00, taxRate: 0.15, deductableValue: 135.00 },
        { upperLimit: Infinity, taxRate: 0.275, deductableValue: 360.00 },
    ],
    dependentValue: 90.00,
  },
  {
    label: '01/1996 - 12/1997',
    start: new Date('1996-01-01'),
    end: new Date('1997-12-31'),
    ranges: [
        { upperLimit: 900.00, taxRate: 0.00, deductableValue: 0.00 },
        { upperLimit: 1800.00, taxRate: 0.15, deductableValue: 135.00 },
        { upperLimit: Infinity, taxRate: 0.25, deductableValue: 315.00 },
    ],
    dependentValue: 90.00,
  },
];